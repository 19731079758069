export const baseEnvActions = {
    auth:{
        login: {
            path: '/Security/Login',
            method: 'POST',
        },
        sendVerificationCode: {
            path: '/admin/security/SendCode',
            method: 'POST',
        },
        verifyCode: {
            path: '/admin/security/LoginCode',
            method: 'POST',
        }
    },
    reports:{
        getAll: (resolve?: boolean, positive?: boolean) => ({
            path: `/Admin/Report/GetAll?resolved=${resolve}&positive=${positive ? positive : ''}`,
            method: 'GET',
        }),
        resolveReports: {
            path: '/Admin/Report/BulkChangeToResolve',
            method: 'PATCH',
        }
    },
    venues: {
        create: {
            path: '/Admin/Venue/Create',
            method: 'POST'
        },
        getAllPaginated: (pageSize: number, pageNumber: number, city: string) => ({
            path: `/Admin/Venue/GetAllPaginated?Pagination.PageSize=${pageSize}&Pagination.PageNumber=${pageNumber}&CityPublicKey=${city}`,
            method: 'GET'
        }),
        getAll: (city: string) => ({
            path: `/Admin/Venue/GetAll?CityPublicKey=${city}`,
            method: 'GET'
        }),
        search: {
            path: '/Admin/Venue/SearchText',
            method: 'POST'
        },
        getOne: (id:string) => ({
            path: `/Admin/Venue/GetByPublicKey/${id}`,
            method: 'GET'
        }),
        update: {
            path: `/Admin/Venue/Edit`,
            method: 'PUT'
        },
        setToActive: {
            path: '/Admin/Venue/BulkChangeToActive',
            method: 'PATCH'
        },
        setToInactive: {
            path: '/Admin/Venue/BulkChangeToInActive',
            method: 'PATCH'
        },
        setToTopPicks: {
            path: '/Admin/Venue/BulkToggleTopPick',
            method: 'PATCH'
        },
        delete: {
            path: '/Admin/Venue/BulkSoftDelete',
            method: 'PUT'
        },
        getAllCities: {
            path: '/Admin/Catalog/GetAllCities',
            method: 'GET'
        },
        getAllAmenities: {
            path: '/Admin/Catalog/GetAllAmenities',
            method: 'GET'
        },
        getAllPlans: {
            path: '/Admin/Catalog/GetAllPayingPlans',
            method: 'GET'
        },
        uploadDocument:{
            path: '/Admin/Document/UploadDocument',
            method: 'POST'
        },
        getDocument: (id:string) => ({
            path: `/Document/GetDocument/${id}`,
            method: 'GET'
        })
    },
}
