<div class="bg-hp-gradient-230 h-[6px]"></div>
<div class="flex justify-between pt-4 pr-4 pl-8">
  <hp-icons [name]="data.iconName"></hp-icons>
  <hp-icons class="cursor-pointer" mat-button mat-dialog-close [mat-dialog-close]="false" name="close-dialog"></hp-icons>
</div>
<div class="px-10 pt-4 pb-8">
  <h2>{{data.title}}</h2>
  <p class=" text-[#68666F] text-[14px] font-normal leading-[24px]">{{data.text}}</p>
  <div class="flex gap-4 pt-7">
    @if (!data.hideCancelButton) {
      <div class="w-full" mat-button mat-dialog-close [mat-dialog-close]="false">
        <hp-button type="alternative" rounded="sm" >Cancel</hp-button>
      </div>
    }
    <div class="w-full" mat-button mat-dialog-close [mat-dialog-close]="true">
      <hp-button type="secondary" rounded="sm">{{data.actionText}}</hp-button>
    </div>
  </div>
</div>