export const documentEndpoints = {

      upload: {
        path: '/Admin/Document/UploadDocument',
        method: 'POST' as const,
      },
      get: (id: string) => ({
        path: `/Document/GetDocument/${id}`,
        method: 'GET' as const,
      }),

  } as const;