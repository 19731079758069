export const offerEndpoints = {
      getAll: {
        path: '/admin/HoppyOffer/GetAll',
        method: 'GET' as const,
      },
      get: {
        path: '/admin/HoppyOffer/Get',
        method: 'GET' as const,
      },
      create: {
        path: '/admin/HoppyOffer/Add',
        method: 'POST' as const,
      },
      update: {
        path: '/admin/HoppyOffer/Update',
        method: 'PUT' as const,
      },
      delete: {
        path: '/admin/HoppyOffer/Remove',
        method: 'DELETE' as const,
      },
    venues: {
      getAll: {
        path: '/admin/HoppyOffer/GetAllVenues',
        method: 'GET' as const,
      },
      get: {
        path: '/admin/HoppyOffer/GetVenue',
        method: 'GET' as const,
      },
      create: {
        path: '/admin/HoppyOffer/AddVenue',
        method: 'POST' as const,
      },
      update: {
        path: '/admin/HoppyOffer/UpdateVenue',
        method: 'PUT' as const,
      },
      delete: {
        path: '/admin/HoppyOffer/RemoveVenue',
        method: 'DELETE' as const,
      },
    }
  } as const;