import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';
import { testGuard } from './shared/guards/test.guard';
import { PlatformLayoutComponent } from './shared/layouts/platform-layout/platform-layout.component';

export const routes: Routes = [{
  path: '',
  loadChildren: () => import('./auth/routes').then(m => m.AUTH_ROUTES),
},
{
  path: 'platform',
  component: PlatformLayoutComponent,
  children: [{
    canActivate: [authGuard],
    path: 'insights',
    loadComponent: () => import('./insights/insights.component').then(m => m.InsightsComponent),
  
  },
  {
    canActivate: [authGuard],
    path: 'venues',
    loadChildren: () => import('./venues/routes').then(m => m.VENUE_ROUTES),
  },
  {
    canActivate: [authGuard],
    path: 'reports',
    loadComponent: () => import('./reports/reports.component').then(m => m.ReportsComponent),
  },
  {
    canActivate: [authGuard],
    path: 'users',
    loadComponent: () => import('./users/users.component').then(m => m.UsersComponent),
  },
  {
    canActivate: [authGuard],
    path: 'offers',
    loadChildren: () => import('./offers/routes').then(m => m.OFFER_ROUTES),
  },
  {
    canActivate: [testGuard],
    path: 'test',
    loadComponent: () => import('./shared/pages/test-view/test-view.component').then(m => m.TestViewComponent)
  }]
}
];
