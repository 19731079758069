import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
  MatDialogModule,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { IconsComponent } from "../../icons/icons.component";
import { ConfirmDialogData } from "src/types/global";
import { ButtonComponent } from "../../button/button.component";

@Component({
  selector: "hp-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    MatDialogModule,
    MatFormFieldModule,
    MatDialogTitle,
    IconsComponent,
    ButtonComponent
  ],
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.sass"],
})
export class ConfirmDialogComponent implements OnInit {
  readonly data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);
  constructor() {}

  ngOnInit() {}
}
