import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GradientTextComponent } from '../gradient-text/gradient-text.component';

@Component({
  selector: 'hp-button',
  standalone: true,
  imports: [CommonModule, GradientTextComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass']
})
export class ButtonComponent implements OnInit {

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'login' = 'md'
  @Input() type: 'primary' | 'secondary' | 'alternative' | 'accent' = 'primary'
  @Input() rounded : 'sm' | 'md' | 'lg' = 'md'
  @Input() fontSize: 'sm' | 'md' | 'lg' | 'xl' = 'md'
  @Input() btnType: 'button' | 'submit' | 'reset' | 'menu' = 'button'
  @Input() paddingX = '35px'
  @Input() paddingY = '16px'
  constructor() { }

  ngOnInit() {
  }

}
