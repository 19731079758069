import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { icons } from './icons';

@Component({
  selector: 'hp-icons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icons.component.html',
})
export class IconsComponent{

  @Input() set name(iconName: string){
    this.element.nativeElement.innerHTML = icons[iconName] ?? null
  }

  constructor(private element: ElementRef) { }


}
