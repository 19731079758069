<mat-drawer-container class="h-screen">
  <mat-drawer
    class="min-w-[296px] bg-hp-gradient-230 px-[30px] pt-[64px]"
    mode="side"
    opened
  >
    <div class="w-full flex flex-col items-center">
      <img src="/assets/svgs/Hoppy-Long.svg" alt="hoppy-logo" />
      <p-accordion
        class="w-full mt-10 mb-[31px] py-[23px] text-white border-t border-b border-[#ffffff50]"
        expandIcon="pi pi-angle-down"
        collapseIcon="pi pi-angle-up"
      >
        <p-accordionTab>
          <ng-template class="flex flex-row-reverse" pTemplate="header">
            <hp-accordion-element
              [image]="currentCity.image"
              [name]="currentCity.name"
            ></hp-accordion-element>
          </ng-template>
          @for (option of cityOptions; track $index) { @if (option.name !==
          currentCity.name && option.image !== currentCity.image) {
          <hp-accordion-element
            class="mt-[23px]"
            [image]="option.image"
            [name]="option.name"
            (click)="setCurrentCity(option)"
            (keydown.space)="setCurrentCity(option)"
          ></hp-accordion-element>
          } }
        </p-accordionTab>
      </p-accordion>
      <ul class="w-full">
        @for (route of routes; track $index) {
        <li>
          <button class="w-full" tabindex="-1" (click)="cleanSessionStorage()">
            <hp-nav-link [route]="route.link" [name]="route.name" ></hp-nav-link>
          </button>
        </li>
        }
        <li>
          <hp-nav-link
            (click)="openDialog()"
            [route]="'#'"
            mat-raised-button
            (keydown.space)="openDialog()"
            name="logout"
          ></hp-nav-link>
        </li>
      </ul>
    </div>
  </mat-drawer>
  <mat-drawer-content
    class="min-h-screen px-[54px] py-[39px] border-r-transparent bg-[#F5F5F5]"
  >
    <!-- <hp-nav-bar-header [title]="navbarTitle">
      <ng-content select="[header]"></ng-content>
    </hp-nav-bar-header>

    <main>
      <ng-content></ng-content>
    </main> -->
    <main class="relative min-h-screen">
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
