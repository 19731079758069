import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AccordionModule } from "primeng/accordion";
import { AccordionElementComponent } from "../../components/accordion-element/accordion-element.component";
import { NavBarHeaderComponent } from "../../components/nav-bar-header/nav-bar-header.component";
import { NavLinkComponent } from "../../components/nav-link/nav-link.component";
import { Router, RouterOutlet } from "@angular/router";
import { CityOption } from "src/types/global";
import { MatDialogModule } from "@angular/material/dialog";
import { ButtonComponent } from "../../components/button/button.component";
import { PlatformService } from "../../services/platform.service";
import { DialogService } from "../../services/dialog.service";

const FLAGS: { [key: string]: string } = {
  Dubai: "/assets/images/dubai-flag.png",
};

@Component({
  selector: "hp-platform-layout",
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    AccordionModule,
    AccordionElementComponent,
    NavBarHeaderComponent,
    NavLinkComponent,
    MatDialogModule,
    ButtonComponent,
    RouterOutlet
  ],
  templateUrl: "./platform-layout.component.html",
  styleUrls: ["./platform-layout.component.sass"],
})
export class PlatformLayoutComponent implements OnInit {
  @Input() navbarTitle = "title";

  currentCity: CityOption = {
    image:
      "",
    name: "",
    entityPublicKey: ""
  };

  cityOptions: CityOption[] = [];

  routes = [
    { link: "/platform/insights", name: "insights" },
    { link: "/platform/venues", name: "venues" },
    { link: "/platform/reports", name: "reports" },
    { link: "/platform/users", name: "users" },
  ];

  constructor(
    private router: Router,
    private dialogSvc: DialogService,
    private platformSvc: PlatformService
  ) {}

  ngOnInit() {
    if(!this.cityOptions.length){
      this.platformSvc.getAllCitiesVenue().subscribe((cities) => {
        if (cities) {
          this.cityOptions = cities.map((city) => ({
            name: city.displayName,
            image: city.thumbnailUrl,
            entityPublicKey: city.entityPublicKey
          }));
          const storageCity = localStorage.getItem('currentCity')
          if(storageCity){
            this.currentCity = JSON.parse(storageCity) 
          }else{
            this.currentCity = this.cityOptions[0]
            localStorage.setItem('currentCity', JSON.stringify(this.currentCity) )
          }
          localStorage.setItem('currentCityPublicKey', this.currentCity.entityPublicKey)
          this.platformSvc.setCurrentCity(this.currentCity.entityPublicKey)
        }
      });
    }
  }

  setCurrentCity(option: CityOption) {
    this.currentCity = option;
    localStorage.setItem('currentCity', JSON.stringify(option) )
    localStorage.setItem('currentCityPublicKey', option.entityPublicKey)
    this.platformSvc.setCurrentCity(option.entityPublicKey)
  }

  logout() {
    localStorage.clear()
    this.platformSvc.setCurrentCity('')
    this.router.navigate(["/"]);
  }

  openDialog() {
    const data = {
      iconName: "gradient-logout",
      title: "Logout",
      text: "Are you sure you want to logout",
      actionText: "Logout",
    }
    const dialogRef = this.dialogSvc.confirmDialog(data)

    dialogRef.afterClosed().subscribe((state: boolean) => {
      if (state) {
        this.logout();
      }
    });
  }
}
