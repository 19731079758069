import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { City } from "src/types/global";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  private citySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public city$: Observable<string> = this.citySubject.asObservable();
  
  constructor(private http: HttpClient) {}

  getAllCitiesVenue() {
    const endpoint = `${environment.baseUrl}${environment.actions.venues.catalogs.getAllCities.path}`;
    return this.http.get<City[]>(endpoint);
  }

  setCurrentCity(city:string){
    this.citySubject.next(city)
  }
}

