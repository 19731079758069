import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IconsComponent } from '../icons/icons.component';

@Component({
  selector: 'hp-nav-link',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, IconsComponent],
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.sass']
})
export class NavLinkComponent implements OnInit {

  @Input() route = '#'
  @Input() name = 'example'

  constructor() { }

  ngOnInit() {
  }

}
