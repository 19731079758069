import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingDialogData } from 'src/types/global';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@Component({
  selector: 'app-loading-dialog',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.sass']
})
export class LoadingDialogComponent implements OnInit {
  readonly data = inject<LoadingDialogData>(MAT_DIALOG_DATA);
  constructor() { }

  ngOnInit() {
  }

}
