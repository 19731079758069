import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData, ErrorDialogData, LoadingDialogData } from 'src/types/global';
import { ConfirmDialogComponent } from '../components/dialogs/confirmDialog/confirm-dialog.component';
import { LoadingDialogComponent } from '../components/dialogs/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from '../components/dialogs/errorDialog/errorDialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog,) { }

  confirmDialog(data: ConfirmDialogData) {
    return this.dialog.open(ConfirmDialogComponent, { data })
  }

  loadingDialog(data: LoadingDialogData){
    return this.dialog.open(LoadingDialogComponent, { data })
  }

  errorDialog(data: ErrorDialogData, seconds = 3){
    const errorDialogRef = this.dialog.open(ErrorDialogComponent, { data })
    if(seconds){
      setTimeout(() => {
        errorDialogRef.close()
      }, seconds * 1000);
    }
  }

}
