export const authEndpoints = {
    login: {
      path: '/Security/Login',
      method: 'POST' as const,
    },
    sendVerificationCode: {
      path: '/admin/security/SendCode',
      method: 'POST' as const,
    },
    verifyCode: {
      path: '/admin/security/LoginCode',
      method: 'POST' as const,
    }
  } as const;