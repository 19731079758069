export const venuesEndpoints = {
    venue: {
      create: {
        path: '/Admin/Venue/Create',
        method: 'POST' as const,
      },
      getAll: {
        path: '/Admin/Venue/GetAll',
        method: 'GET' as const,
      },
      search: {
        path: '/Admin/Venue/SearchText',
        method: 'POST' as const,
      },
      getOne: (id: string) => ({
        path: `/Admin/Venue/GetByPublicKey/${id}`,
        method: 'GET' as const,
      }),
      update: {
        path: '/Admin/Venue/Edit',
        method: 'PUT' as const,
      },
      setToActive: {
        path: '/Admin/Venue/BulkChangeToActive',
        method: 'PATCH' as const,
      },
      setToInactive: {
        path: '/Admin/Venue/BulkChangeToInActive',
        method: 'PATCH' as const,
      },
      setToTopPicks: {
        path: '/Admin/Venue/BulkToggleTopPick',
        method: 'PATCH' as const,
      },
      delete: {
        path: '/Admin/Venue/BulkSoftDelete',
        method: 'PUT' as const,
      },
    },
    catalogs: {
      getAllCities: {
        path: '/Admin/Catalog/GetAllCities',
        method: 'GET' as const,
      },
      getAllAmenities: {
        path: '/Admin/Catalog/GetAllAmenities',
        method: 'GET' as const,
      },
    },
  } as const;