<span class="flex items-center gap-2 cursor-pointer">
  @if (image) {
    <img
      class="h-[35px] w-[35px] rounded-full"
      [src]="image"
      alt="Amy Elsner"
      />
  }@else{
    <p-skeleton shape="circle" size="2.5rem" />
  }
  @if(name){
    <span class="font-bold white-space-nowrap">{{name}}</span>
  }@else {
    <p-skeleton height="1rem" width="4rem" borderRadius="16px"/>
  }
</span>
