import { HttpErrorResponse, HttpInterceptorFn  } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { DialogService } from "../services/dialog.service";

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const dialogSvc = inject(DialogService);
  // Check if the request URL matches the specific service endpoint
  if (!req.url.includes("/Security/Login")) {
    const router = inject(Router);
    const token = localStorage.getItem("token");
    const modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next(modifiedReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          const errorDialogRef = dialogSvc.errorDialog({message: err.error.message}, 4)
          // Handle HTTP errors
          if (err.status === 401 || err.status === 403 || err.status === 400) {
            // Specific handling for unauthorized errors         
            console.error('Unauthorized request:', err);
            router.navigate(['/']);
            // You might trigger a re-authentication flow or redirect the user here
          } else {
            // Handle other HTTP error codes
            console.error('HTTP error:', err);
          }
        } else {
          // Handle non-HTTP errors
          console.error('An error occurred:', err);
        }
        return throwError(() => err); 
      })
    );;
  }

  return next(req);
};
