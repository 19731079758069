<button
  class="btn"
  [ngClass]="{
    'h-[35px]': size === 'xs',
    'h-10': size === 'sm',
    'h-12': size === 'md',
    'h-[3.250rem]': size === 'lg',
    'h-[5.250rem]': size === 'login',
    btn__primary: type === 'primary',
    btn__secondary: type === 'secondary',
    btn__alternative: type === 'alternative',
    btn__accent: type === 'accent',
    'rounded-[8px]': rounded === 'sm',
    'rounded-[15px]': rounded === 'md',
    'rounded-[75px]': rounded === 'lg',
    'text-[0.750rem]': fontSize === 'sm',
    'text-[0.8750rem]': fontSize === 'md',
    'text-[0.9375rem]': fontSize === 'lg',
    'text-[1rem]': fontSize === 'xl',
  }"
  [type]="btnType"
  [style.padding-left]="paddingX"
  [style.padding-right]="paddingX"
  [style.padding-top]="paddingY"
  [style.padding-bottom]="paddingY"

>
  <ng-content></ng-content>
</button>
