import { authEndpoints } from "./auth";
import { documentEndpoints } from "./documents";
import { insightsEndpoints } from "./insights";
import { offerEndpoints } from "./offer";
import { reportsEndpoints } from "./reports";
import { usersEndpoints } from "./users";
import { venuesEndpoints } from "./venues";

export const apiEndpoints = {
    auth: authEndpoints,
    insights: insightsEndpoints,
    offers: offerEndpoints,
    reports: reportsEndpoints,
    users: usersEndpoints,
    venues: venuesEndpoints,
    documents: documentEndpoints
  } as const;