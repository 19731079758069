import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'hp-accordion-element',
  standalone: true,
  imports: [CommonModule, SkeletonModule],
  templateUrl: './accordion-element.component.html',
  styleUrls: ['./accordion-element.component.sass']
})
export class AccordionElementComponent implements OnInit {

  @Input() image = '';
  @Input() name = '';

  constructor() { }

  ngOnInit() {
  }

}
