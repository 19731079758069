import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { ErrorDialogData } from 'src/types/global';
import { IconsComponent } from '../../icons/icons.component';

@Component({
  selector: 'app-errorDialog',
  standalone: true,
  imports: [IconsComponent, MatDialogClose,],
  templateUrl: './errorDialog.component.html',
  styleUrls: ['./errorDialog.component.sass']
})
export class ErrorDialogComponent implements OnInit {
  readonly data = inject<ErrorDialogData>(MAT_DIALOG_DATA);
  constructor() { }

  ngOnInit() {
  }

}
